/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background-color: #f4f4f9;
  color: #333;
}

.service-page {
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.service-page:hover {
  transform: translateY(-10px);
}

.service-page h1 {
  font-size: 2.8em;
  margin-bottom: 20px;
  color: #007BFF;
  text-align: center;
}

.service-page img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.service-page section {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 8px;
  background: #f9f9f9;
}

.service-page h2 {
  font-size: 2.2em;
  margin-bottom: 15px;
  color: #0056b3;
}

.service-page p,
.service-page ul,
.service-page blockquote {
  font-size: 1.2em;
}

.service-page ul {
  list-style-type: none;
  /* Remove default list bullets */
  padding-left: 0;
}

.service-page li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.service-page li svg {
  margin-right: 10px;
  min-width: 24px;
}

.service-page li strong {
  min-width: 150px;
}

.service-testimonials blockquote {
  font-style: italic;
  margin: 20px 0;
  padding: 10px 20px;
  background: #e9ecef;
  border-left: 5px solid #007BFF;
  border-radius: 8px;
}

.service-testimonials cite {
  display: block;
  margin-top: 10px;
  font-size: 1em;
  font-weight: bold;
  color: #007BFF;
}

.service-cta {
  text-align: center;
}

.service-cta p {
  font-size: 1.2em;
}

.service-cta a {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.service-cta a:hover {
  background-color: #0056b3;
  text-decoration: none;
}

/* Animations */
.service-page img {
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}